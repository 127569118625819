<template>
  <div class="sector-datatable">
    <div class="sector__card-table">
      <table v-if="filterBudget.length">
        <thead>
          <th>आयोजना र कार्यक्रम</th>
          <th>वर्ग</th>
          <th>वडा</th>
          <th>कूल बजेट</th>
          <th>नेपाल सरकारबाट</th>
        </thead>
        <tbody>
          <tr v-for="(ele, index) in filterBudget" :key="index">
            <td>{{ ele.name }}</td>
            <td>{{ ele.category }}</td>
            <td>{{ englishToNepaliNumber(ele.ward) }}</td>
            <td>{{ englishToNepaliNumber(ele.approved_amount) }}</td>
            <td>{{ englishToNepaliNumber(ele.source_gov) }}</td>
          </tr>
        </tbody>
      </table>
      <NoDataFound v-else />
    </div>
  </div>
</template>
<script>
import NoDataFound from "./../../components/NoDataFound.vue";
import { englishToNepaliNumber } from "nepali-number";

export default {
  props: {
    bugetdetails: { type: Array, required: false },
  },
  components: {
    NoDataFound,
  },
  data() {
    return {
      englishToNepaliNumber,
      type: this.$route.params.type,
    };
  },
  computed: {
    filterBudget() {
      return this.bugetdetails.filter((ele) => {
        if (ele.sector == this.$store.state.sectors[this.type].name) {
          return ele;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.title-filter {
  justify-content: space-between;
  padding-right: 40px;
}

.sector__card-table {
  padding: 0;
}

.sector-datatable {
  margin-left: -40px;
  margin-right: -40px;
}

table {
  width: 100%;
  th,
  td {
    padding: 20px 32px;
    text-align: left;
  }
  thead th {
    border-bottom: 1px solid #c4c4c4;
  }
  tbody tr {
    border-bottom: 2px solid $primary-light;
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
